import { render, staticRenderFns } from "./Addons.vue?vue&type=template&id=ab5be6f8&scoped=true&"
import script from "./Addons.vue?vue&type=script&lang=js&"
export * from "./Addons.vue?vue&type=script&lang=js&"
import style0 from "./Addons.vue?vue&type=style&index=0&id=ab5be6f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab5be6f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VApp,VBreadcrumbs,VCard,VCol,VContainer,VDivider,VFlex,VIcon,VLayout,VRow})
