var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"red","size":"40","width":3}})],1),_c('v-card',{attrs:{"color":"transparent","outlined":"","max-width":"600"}},[_c('h2',{staticClass:"page_title mt-16 mb-13"},[_vm._v(" Select your Virtual Number ")]),_c('h2',{staticClass:"sub_title mt-8 mb-13"},[_c('br')]),_c('div',[(_vm.V_numbers[0])?_c('v-radio-group',{model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{style:(_vm.radio1
                            ? 'border: 1px solid #EE1C25;border-radius: 10px;'
                            : 'border: 1px solid #B4B4B4;border-radius: 10px;'),on:{"click":function($event){return _vm.colorChange(_vm.radio, _vm.V_numbers[0])}}},[_c('v-radio',{staticClass:"ml-4",attrs:{"color":"red","value":"1"}},[_c('span',{staticClass:"black--text ml-3",attrs:{"slot":"label"},slot:"label"},[(_vm.V_numbers[0])?_c('h2',{staticClass:"page_title mt-3 ml-2 mb-5"},[_vm._v(" +91 "+_vm._s(_vm.V_numbers[0])+" ")]):_vm._e()])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{style:(_vm.radio2
                            ? 'border:1px solid #EE1C25;border-radius: 10px;'
                            : 'border: 1px solid #B4B4B4;border-radius: 10px;'),on:{"click":function($event){return _vm.colorChange(_vm.radio, _vm.V_numbers[1])}}},[_c('v-radio',{staticClass:"ml-4",attrs:{"color":"red","value":"2"}},[_c('span',{staticClass:"black--text ml-3",attrs:{"slot":"label"},slot:"label"},[(_vm.V_numbers[0])?_c('h2',{staticClass:"page_title mt-3 ml-2 mb-5"},[_vm._v(" +91 "+_vm._s(_vm.V_numbers[1])+" ")]):_vm._e()])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{style:(_vm.radio3
                            ? 'border: 1px solid #EE1C25;border-radius: 10px;'
                            : 'border: 1px solid #B4B4B4;border-radius: 10px;'),on:{"click":function($event){return _vm.colorChange(_vm.radio, _vm.V_numbers[2])}}},[_c('v-radio',{staticClass:"ml-4",attrs:{"color":"red","value":"3"}},[_c('span',{staticClass:"black--text ml-3",attrs:{"slot":"label"},slot:"label"},[(_vm.V_numbers[0])?_c('h2',{staticClass:"page_title mt-3 ml-2 mb-5"},[_vm._v(" +91 "+_vm._s(_vm.V_numbers[2])+" ")]):_vm._e()])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{style:(_vm.radio4
                            ? 'border: 1px solid #EE1C25;border-radius: 10px;'
                            : 'border: 1px solid #B4B4B4;border-radius: 10px;'),on:{"click":function($event){return _vm.colorChange(_vm.radio, _vm.V_numbers[3])}}},[_c('v-radio',{staticClass:"ml-4",attrs:{"color":"red","value":"4"}},[_c('span',{staticClass:"black--text ml-3",attrs:{"slot":"label"},slot:"label"},[(_vm.V_numbers[0])?_c('h2',{staticClass:"page_title mt-3 ml-2 mb-5"},[_vm._v(" +91 "+_vm._s(_vm.V_numbers[3])+" ")]):_vm._e()])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{style:(_vm.radio5
                            ? 'border: 1px solid #EE1C25;border-radius: 10px;'
                            : 'border: 1px solid #B4B4B4;border-radius: 10px;'),on:{"click":function($event){return _vm.colorChange(_vm.radio, _vm.V_numbers[4])}}},[_c('v-radio',{staticClass:"ml-4",attrs:{"color":"red","value":"5"}},[_c('span',{staticClass:"black--text ml-3",attrs:{"slot":"label"},slot:"label"},[(_vm.V_numbers[0])?_c('h2',{staticClass:"page_title mt-3 ml-2 mb-5"},[_vm._v(" +91 "+_vm._s(_vm.V_numbers[4])+" ")]):_vm._e()])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{style:(_vm.radio6
                            ? 'border: 1px solid #EE1C25;border-radius: 10px;'
                            : 'border: 1px solid #B4B4B4;border-radius: 10px;'),on:{"click":function($event){return _vm.colorChange(_vm.radio, _vm.V_numbers[5])}}},[_c('v-radio',{staticClass:"ml-4",attrs:{"color":"red","value":"6"}},[_c('span',{staticClass:"black--text ml-3",attrs:{"slot":"label"},slot:"label"},[(_vm.V_numbers[0])?_c('h2',{staticClass:"page_title mt-3 ml-2 mb-5"},[_vm._v(" +91 "+_vm._s(_vm.V_numbers[5])+" ")]):_vm._e()])])],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"mt-4"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-reload")]),_c('span',{staticClass:"notif-mark"},[_vm._v(" Refresh List ("+_vm._s(_vm.timerCount)+"s)")])],1),(_vm.radio != null)?_c('v-btn',{staticClass:"btn_text mt-10 white--text text-capitalize",attrs:{"width":"19%","rounded":"","color":"#EE1C25"},on:{"click":function($event){$event.preventDefault();return _vm.reserveNumber()}}},[_vm._v(" Next ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }